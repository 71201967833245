$color--primary: #1DA5FF
$color--primary-gradient: linear-gradient(269.85deg, rgba(29, 165, 255, 0.56) -63%, #1DA5FF 103.78%)
$color--lightblue-acc: #DBEFFF
$color--lightblue-light: #F4FAFF
$color--gray-light: #c5c5c5

$text--regular: #44525B

=r($max)
    @media screen and (max-width: $max+'px')
        @content

=h($max)
    @media screen and (max-height: $max+'px')
        @content

.hidden
    display: none!important

.scroll-container
    margin: 0 0 auto 0

button
    -webkit-touch-callout: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    -o-user-select: none
    user-select: none 
    img
        -webkit-touch-callout: none
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        -o-user-select: none
        user-select: none

.chat
    display: grid
    grid-template-rows: 92px 1fr 60px
    height: 100vh
    font-family: Roboto, sans-serif
    overflow: hidden
    background-color: $color--lightblue-light
    box-shadow: 0px 0px 5px rgba(29,165,255,.25), 0px 0px 60px rgba(29,165,255,.13)
    +r(450)
        right: 0
        max-width: 100%
        height: 100vh
    +h(450)
        height: 100vh
    &__top
        width: 100%
        padding: 36px 28px 18px
        background-image: $color--primary-gradient
        display: flex
        justify-content: space-between
        align-items: center
        +h(450)
            padding: 12px
        &-item
            display: flex
            gap: 8px
            align-items: center
            &:last-child
                .item-img
                    width: 24px
                    height: 24px
                    +h(450)
                        width: 18px
                        height: 18px
            .item-img
                width: 38px
                height: 38px
                +h(450)
                    width: 24px
                    height: 24px
        &-text
            color: #fff
            margin-left: 12px
        &-title
            font-size: 17px
            font-weight: 600
            +h(450)
                font-size: 14px
        &-desc
            font-size: 14px
            font-weight: 400
            +h(450)
                font-size: 10px
    &__center
        padding-bottom: 26px
        position: relative
        display: flex
        width: 100%
        min-height: 168px
        +r(450)
            flex-grow: 1
        +h(450)
            flex-grow: 1
            min-height: 168px
        .messages
            width: calc(100% + 17px)
            height: 100%
            display: flex
            flex-direction: column
            align-items: center
            padding: 0 16px 32px
            margin-right: -17px
            height: 100%
            overflow-y: scroll
            & > :first-child
                margin-top: auto!important
            +r(450)
                width: 100%
            +h(450)
                width: 100%
            &-item
                width: calc(100% - 20px)
                padding: 8px 12px
                font-size: 16px
                font-weight: 400
                line-height: 21px
                display: flex
                justify-content: flex-start
                align-items: flex-start
                position: relative
                &[data-type="client"]
                    color: $color--lightblue-light
                    border-radius: 12px 12px 0px 12px
                    background-color: $color--primary
                    margin: 16px 0 0 auto
                    .messages-item-time
                        color: #EAF6FF
                &[data-type="live_agent"]
                    color: $color--primary
                    border-radius: 12px 12px 12px 0
                    background-color: $color--lightblue-acc
                    margin: 16px auto 0 0
                    .messages-item-time
                        display: flex
                        align-items: center
                        justify-content: center
                        gap: 4px
                        padding-top: 20px
                        color: rgba($color--primary, .5)
                &[data-type="ai"]
                    color: $color--primary
                    border-radius: 12px 12px 12px 0
                    background-color: $color--lightblue-acc
                    margin: 16px auto 0 0
                    .messages-item-time
                        display: flex
                        align-items: center
                        justify-content: center
                        gap: 4px
                        padding-top: 20px
                        color: rgba($color--primary, .5)
                img
                    width: 21px
                    height: 21px
                    margin-right: 8px
                &-time
                    position: absolute
                    right: 12px
                    bottom: 2px
                    font-size: 10px
                    font-weight: 400
                    font-family: Roboto, sans-serif
                span
                    &:last-child
                        min-height: 20px
    &__bottom
        width: 100%
        display: flex
        align-items: center
        border-top: 1px solid #DBEFFF
        background-color: #fff
        position: relative
        &-record-line
            position: absolute
            bottom: 95%
            left: 0
            right: 0
        &-form
            width: 100%
            height: 60px
            display: flex
            justify-content: space-between
            align-items: center
            padding-right: 16px
            &-msg
                width: calc(100% - 12px)
                height: inherit
                padding: 20px 16px
                border: initial
                outline: none
                font-size: 16px
                font-weight: 400
                line-height: 20px
                color: $text--regular
                &::placeholder
                    font-size: 16px
                    font-weight: 400
                    line-height: 20px
                    color: $color--gray-light
            &-btn
                width: 24px
                height: 24px
                display: inline-flex
                justify-content: center
                align-items: center
                border: initial
                background-color: transparent
                outline: none
                cursor: pointer
                &:active, &:focus-visible, &:focus-within, &:focus
                    outline: none
                    background-color: transparent
                    border: none
                &-mic
                    width: 44px
                    height: 44px
                    margin-left: 12px
                    margin-right: 12px
                    padding: 8px
                    background-color: red
                    border-radius: 50%
                    border-bottom-right-radius: 0
                    img
                        &:focus-visible, &:focus-within, &:focus, &:active
                            outline: none
                            background-color: transparent
                            border: none

.sound-wave
    width: 100%
    border-radius: 24px 24px 0px 0px
    height: 104px

