body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #1da5ff;
}

.modal-enter-active {
  animation: moveUp 0.3s cubic-bezier(0.33, 0, 0, 1) forwards;
}

.modal-exit-active {
  animation: moveDown 0.2s cubic-bezier(0.33, 0, 0, 1) forwards;
}

@keyframes moveUp {
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes moveDown {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, 100%);
  }
}
